.fullscreen-container {
    padding: 0;
    position: relative;
}
.backgroundVideoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
}
.backgroundVideoContainer video{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.backgroundVideoContainer img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.backgroundVideoContainer .bgOverlay{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.664);
    position: absolute;
}

.heroClientCard{
    padding: 20px 50px;
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heroClientCard p{
    font-weight: 600;
    font-size: 16px;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .heroClientCard{
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top:30px;
    }
}

@media (min-width: 581px) and (max-width: 767px){
    .heroClientCard{
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top:30px;
        padding: 15px 35px;
    }
}

@media only screen and (max-width: 581px) {
    .apply-now-text{
        margin-bottom: 20px;
        display: none;
    }

    .heroClientCard{
        display: none;
    }
}
