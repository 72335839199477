.side-menu {
  width: 40%;
  position: fixed;
  right: 0;
  top: 0;
  /*background: #00bcd4;*/
  z-index: 1032;
  height: 100%;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease,
    -o-transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  overflow: hidden;
  background: #3264f5;
  background: -moz-linear-gradient(left, #3264f5 2%, #7202bb 82%);
  background: -webkit-linear-gradient(left, #3264f5 2%, #7202bb 82%);
  background: linear-gradient(to right, #3264f5 2%, #7202bb 82%);
}
.side-menu {
  width: 380px;
}
.side-menu p {
  font-size: 13px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.side-menu.left {
  left: 0;
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.side-menu.before-side {
  width: 280px;
}

.side-menu.before-side,
.side-menu.side-menu-active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.pul-menu .side-menu.side-menu-active {
  visibility: visible;
  opacity: 1;
}

.side-menu .navbar-brand {
  margin: 0 0 2.5rem 0;
}

#close_side_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-transition: opacity 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -o-transition: opacity 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  display: none;
  z-index: 1031;
  opacity: 0.4;
}

.side-menu .btn-close {
  height: 24px;
  width: 24px;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 32px;
  cursor: pointer;
}

.side-menu.before-side .btn-close {
  display: none;
}

.side-menu .btn-close::after,
.side-menu .btn-close::before {
  position: absolute;
  left: 16px;
  content: " ";
  height: 24px;
  width: 2px;
  background: #fff;
  top: 5px;
}

.side-menu .btn-close:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.side-menu .btn-close:after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.side-menu .inner-wrapper {
  padding: 5rem 2rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pul-menu.pushwrap .side-menu .inner-wrapper {
  padding: 3.5rem 2.5rem;
}

.side-menu .side-nav {
  margin-bottom: 30px;
  display: block;
}
.side-nav .navbar-nav .nav-item {
  display: block;
  padding: 0 !important;
  opacity: 0;
  -webkit-transition: all 0.8s ease 0.5s;
  -o-transition: all 0.8s ease 0.5s;
  transition: all 0.8s ease 0.5s;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.side-nav .navbar-nav .nav-item i {
  font-size: 1.5rem;
  color: #fff;
  margin-left: 5px;
}

.side-nav .navbar-nav .nav-item:first-child {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.side-nav .navbar-nav .nav-item:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.side-nav .navbar-nav .nav-item:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.side-nav .navbar-nav .nav-item:nth-child(4) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.side-nav .navbar-nav .nav-item:nth-child(5) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.side-nav .navbar-nav .nav-item:nth-child(6) {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.side-nav .navbar-nav .nav-item:nth-child(7) {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.side-nav .navbar-nav .nav-item:nth-child(8) {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.side-nav .navbar-nav .nav-item:nth-child(9) {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.side-nav .navbar-nav .nav-link {
  display: inline-table;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 24px 10px;
  width: 100%;
}

.side-nav .navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.side-nav .navbar-nav .nav-link ::after,
.side-nav .navbar-nav .nav-link:focus::after,
.side-nav .navbar-nav .nav-link:hover::after {
  width: 100%;
}

.side-nav .navbar-nav .nav-link.active {
  background: 0 0;
}

.social-icons-simple {
  margin-bottom: 0;
}

.social-icons-simple li {
  display: inline-block;
}

.social-icons-simple li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 25px;
  line-height: 38px;
  border: 1px solid transparent;
  text-align: center;
  width: 25px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.social-icons-simple li a:hover {
  background-color: #fff;
}

.social-icons-simple li.animated-wrap a:hover {
  background-color: transparent;
  color: #fff;
}
@media (min-width: 768px) and (max-width: 1280px) {
  .navbar .css-vpbpzp-control {
    display: none !important;
  }
}
@media (max-width: 1280px) {
  .css-1oj4par-control {
    margin-left: 10px;
    width: 100%;
  }
  .css-vpbpzp-control {
    margin-left: 10px;
    width: 100%;
  }
}
@media only screen and (max-height: 700px) and (max-width: 767px) {
  .side-menu {
    width: 340px;
  }
}
@media screen and (max-width: 576px) {
  .side-menu {
    width: 300px;
  }
}
@media screen and (max-width: 466px) {
  .side-menu {
    width: 280px;
  }
}
@media only screen and (max-device-width: 466px) {
  .side-menu {
    width: 298px;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .side-menu {
    width: 42%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .side-menu {
    width: 30%;
  }
}
