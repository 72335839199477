.clientele-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 105px;
  max-width: 100%;
}
.slide-container-cli {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 60px;
}
.clientele-right {
  width: 40%;
}
.clientele-left {
  width: 60%;
}
.image-row-cli {
  display: flex;
  gap: 1.33%;
  justify-content: flex-start;
  align-items: center;
}
.clienteleImageContainer {
  position: relative;
  width: 24%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.clienteleImageContainer img {
  position: absolute;
  transform:scale(0.22);
}
.clienteleImageContainer:hover {
  transform: translateY(-5px); 
  transition: 0.5s;
}

.clienteleImageContainer .bg-1 {
  width: 952px; height: 173px;
  object-position: -10px -2528px;
  object-fit: none;
}

.clienteleImageContainer .bg-2 {
  width: 372px; height: 400px;
  object-position: -3300px -430px;
  object-fit: none;
}

.clienteleImageContainer .bg-3 {
  width: 801px; height: 280px;
  object-position: -689px -1150px;
  object-fit: none;
}

.clienteleImageContainer .bg-4 {
  width: 776px; height: 280px;
  object-position: -1722px -310px;
  object-fit: none;
}

.clienteleImageContainer .bg-5 {
  width: 400px; height: 400px;
  object-position: -2520px -1390px;
  object-fit: none;
}

.clienteleImageContainer .bg-6 {
  width: 400px; height: 400px;
  object-position: -2520px -1810px;
  object-fit: none;
}

.clienteleImageContainer .bg-7 {
  width: 385px; height: 400px;
  object-position: -3300px -10px;
  object-fit: none;
}

.clienteleImageContainer .bg-8 {
  width: 665px; height: 240px;
  object-position: -2520px -1130px;
  object-fit: none;
}

.clienteleImageContainer .bg-9 {
  width: 860px; height: 280px;
  object-position: -798px -810px;
  object-fit: none;
}

.clienteleImageContainer .bg-10 {
  width: 778px; height: 320px;
  object-position: -743px -430px;
  object-fit: none;
}

.clienteleImageContainer .bg-11 {
  width: 314px; height: 400px;
  object-position: -3300px -850px;
  object-fit: none;
}

.clienteleImageContainer .bg-12 {
  width: 952px; height: 216px;
  object-position: -978px -1806px;
  object-fit: none;
}

.clienteleImageContainer .bg-13 {
  width: 834px; height: 256px;
  object-position: -10px -1530px;
  object-fit: none;
}

.clienteleImageContainer .bg-14 {
  width: 870px; height: 176px;
  object-position: -937px -2721px;
  object-fit: none;
}

.clienteleImageContainer .bg-15 {
  width: 907px; height: 176px;
  object-position: -10px -2721px;
  object-fit: none;
}

.clienteleImageContainer .bg-16 {
  width: 952px; height: 176px;
  object-position: -1749px -2292px;
  object-fit: none;
}

.clienteleImageContainer .bg-17 {
  width: 883px; height: 240px;
  object-position: -864px -1530px;
  object-fit: none;
}

.clienteleImageContainer .bg-18 {
  width: 740px; height: 240px;
  object-position: -2520px -270px;
  object-fit: none;
}

.clienteleImageContainer .bg-19 {
  width: 948px; height: 160px;
  object-position: -982px -2528px;
  object-fit: none;
}

.clienteleImageContainer .bg-20 {
  width: 520px; height: 400px;
  object-position: -1722px -610px;
  object-fit: none;
}

.clienteleImageContainer .bg-21 {
  width: 948px; height: 144px;
  object-position: -1950px -2528px;
  object-fit: none;
}

.clienteleImageContainer .bg-22 {
  width: 722px; height: 184px;
  object-position: -1767px -1530px;
  object-fit: none;
}

.clienteleImageContainer .bg-23 {
  width: 948px; height: 119px;
  object-position: -809px -2917px;
  object-fit: none;
}

.clienteleImageContainer .bg-24 {
  width: 779px; height: 184px;
  object-position: -10px -2917px;
  object-fit: none;
}

.clienteleImageContainer .bg-25 {
  width: 878px; height: 200px;
  object-position: -851px -2292px;
  object-fit: none;
}

.clienteleImageContainer .bg-26 {
  width: 730px; height: 280px;
  object-position: -1722px -1030px;
  object-fit: none;
}

.clienteleImageContainer .bg-27 {
  width: 939px; height: 280px;
  object-position: -763px -10px;
  object-fit: none;
}

.clienteleImageContainer .bg-28 {
  width: 860px; height: 224px;
  object-position: -892px -2048px;
  object-fit: none;
}

.clienteleImageContainer .bg-29 {
  width: 821px; height: 216px;
  object-position: -10px -2292px;
  object-fit: none;
}

.clienteleImageContainer .bg-30 {
  width: 778px; height: 280px;
  object-position: -1722px -10px;
  object-fit: none;
}

.clienteleImageContainer .bg-31 {
  width: 862px; height: 224px;
  object-position: -10px -2048px;
  object-fit: none;
}

.clienteleImageContainer .bg-32 {
  width: 733px; height: 400px;
  object-position: -10px -10px;
  object-fit: none;
}

.clienteleImageContainer .bg-33 {
  width: 588px; height: 280px;
  object-position: -2520px -830px;
  object-fit: none;
}

.clienteleImageContainer .bg-34 {
  width: 760px; height: 240px;
  object-position: -2520px -10px;
  object-fit: none;
}

.clienteleImageContainer .bg-35 {
  width: 948px; height: 222px;
  object-position: -10px -1806px;
  object-fit: none;
}

.clienteleImageContainer .bg-36 {
  width: 626px; height: 280px;
  object-position: -2520px -530px;
  object-fit: none;
}

.clienteleImageContainer .bg-37 {
  width: 948px; height: 144px;
  object-position: -1827px -2721px;
  object-fit: none;
}

.clienteleImageContainer .bg-38 {
  width: 713px; height: 360px;
  object-position: -10px -430px;
  object-fit: none;
}

.clienteleImageContainer .bg-39 {
  width: 659px; height: 360px;
  object-position: -10px -1150px;
  object-fit: none;
}

.clienteleImageContainer .bg-40 {
  width: 768px; height: 320px;
  object-position: -10px -810px;
  object-fit: none;
}
@media (min-width: 2000px) {
  .clienteleImageContainer img{
    transform: scale(0.25);
  }
}
@media (max-width: 1730px) {
  .clienteleImageContainer img{
    transform: scale(0.22);
  }
}
@media (max-width: 1680px) {
  .clienteleImageContainer img{
    transform: scale(0.2);
  }
}
@media (max-width: 1560px) {
  .clienteleImageContainer img{
    transform: scale(0.18);
  }
}
@media (max-width: 1420px) {
  .clienteleImageContainer img{
    transform: scale(0.16);
  }
}
@media (max-width: 1300px) {
  .clienteleImageContainer img{
    transform: scale(0.15);
  }
}
@media (max-width: 1230px) {
  .clienteleImageContainer img{
    transform: scale(0.14);
  }
}
@media (max-width: 1150px) {
  .clienteleImageContainer img{
    transform: scale(0.13);
  }
}
@media (max-width: 1090px) {
  .clienteleImageContainer img{
    transform: scale(0.12);
  }
}
@media (max-width: 1280px) {
  .clienteleImageContainer {
    height: 85px;
  }
} 

@media (max-width: 1024px) {
  .clientele-container {
    margin: 0 50px;
  }
  .clientele-right img {
    width: 90% !important;
  }
}
@media (max-width: 920px) {
  .clienteleImageContainer img{
    transform: scale(0.10);
  }
}
@media (min-width: 769px) {
  .clientele-right {
    text-align: right;
  }
}
@media (max-width: 768px) {
  .clientele-container {
    margin: 0 50px;
  }
  .slide-container-cli {
    gap: 40px;
    margin-bottom: 40px;
  }
  .clientele-right img {
    width: 100% !important;
  }
  .clientele-image-row {
    padding: 10px;
  }
  .clientele-right {
    width: 100%;
  }
  .clientele-left {
    gap: 0;
    width: 100%;
  }
  .clientele-container {
    flex-direction: column;
    gap: 30px;
  }
  .image-row-cli {
    gap: 5%;
  }
  .clienteleImageContainer img{
    transform: scale(0.2);
  }
  .clienteleImageContainer {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .clientele-container {
    margin: 0 25px;
  }
  .clienteleImageContainer img{
    transform: scale(0.2);
  }
}
@media (max-width: 720px) {
  .clienteleImageContainer img{
    transform: scale(0.18);
  }
}
@media (max-width: 650px) {
  .clienteleImageContainer img{
    transform: scale(0.16);
  }
}
@media (max-width: 580px) {
  .clienteleImageContainer img{
    transform: scale(0.14);
  }
}
@media (max-width: 520px) {
  .clienteleImageContainer img{
    transform: scale(0.12);
  }
}
@media (max-width: 480px) {
  .clientele-left {
    gap: 0;
    width: 100%;
  }
  .clientele-right {
    width: 100%;
  }
  .clientele-container {
    flex-direction: column;
    margin: 0 16px;
  }
  .image-row-cli {
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
  }
  .clienteleImageContainer img{
    transform: scale(0.2);
  }
  .clienteleImageContainer {
    width: 49%;
  }
} 
@media (max-width: 450px) {
  .clienteleImageContainer img{
    transform: scale(0.18);
  }
}
@media (max-width: 410px) {
  .clienteleImageContainer img{
    transform: scale(0.16);
  }
}
@media (max-width: 370px) {
  .clienteleImageContainer img{
    transform: scale(0.14);
  }
}
@media (max-width: 330px) {
  .clienteleImageContainer img{
    transform: scale(0.12);
  }
} 
