.card-gwr {
  height: 250px;
  background: linear-gradient(90deg, #5928d1 0%, #7444eb 100%);
  border-radius: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.card-gwr:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.gwr-container {
  margin: 0px 120px;
}

.hover-gwr {
  margin-top: 5px;
}
.gwr-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.gwr-desc {
  margin-top: 24px !important;
  font-size: 20px;
  font-weight: 600;
}
#guinness-world-records .title {
  margin-bottom: 0.5px !important;
}

.gwr-link {
  color: #7444eb;
}

.card-gwr:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0px;
  bottom: -120px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("../../../../public/images/vector_party.png");
  background-repeat: no-repeat;
  background-position: 0% 0px;
}

.img-gwr {
  width: 60%;
}

.card-text-gwr {
  height: fit-content;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.text-gwr {
  margin-top: 5px;
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.logo-ind {
  height: 50px;
  width: 60px;
}

.b-text {
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  position: relative;
}

.bottom-text-gwr {
  color: #5928d1;
  text-align: center;
  margin-top: 48px !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.main-text-gwr {
  color: #343a40;
  text-align: center;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0px !important;
}

.gwr-progress-bar {
  position: relative;
  width: 100%;
  max-width: 160px;
  height: 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 8px;
}

.gwr-progress-bar .gwr-progress-segment {
  height: 100%;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
}

.gwr-progress-bar .gwr-progress-segment.active {
  background-color: #5928d1;
  width: 80px !important;
  cursor: default;
}
.gwrImageContainer {
  position: relative;
  overflow: hidden;
  height: 50px;
  width: 60px;
}
.gwrImageContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(0.8);
}
.gwrImageContainer .bg-1 {
  width: 60px;
  height: 60px;
  object-position: -10px -10px;
  object-fit: none;
}

.gwrImageContainer .bg-2 {
  width: 58px;
  height: 60px;
  object-position: -170px -10px;
  object-fit: none;
}

.gwrImageContainer .bg-3 {
  width: 60px;
  height: 60px;
  object-position: -90px -10px;
  object-fit: none;
}

.gwrImageContainer .bg-4 {
  width: 60px;
  height: 60px;
  object-position: -10px -90px;
  object-fit: none;
}

.gwrImageContainer .bg-5 {
  width: 60px;
  height: 60px;
  object-position: -90px -90px;
  object-fit: none;
}

.gwrImageContainer .bg-6 {
  width: 50px;
  height: 60px;
  object-position: -170px -90px;
  object-fit: none;
}
@media (max-width: 1024px) {
  .gwr-container {
    margin: 0px 65px;
  }
}

@media (max-width: 767px) {
  .gwr-container {
    margin: 0px 40px;
  }
}

@media (max-width: 768px) {
  .gwr-desc {
    font-size: 20px;
    margin-top: 16px !important;
  }
}
@media (max-width: 480px) {
  .gwr-container {
    margin: 0px 30px;
  }
}
@media (max-width: 425px) {
  .gwr-desc {
    font-size: 20px;
    margin-top: 8px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .img-gwr {
    width: 55%;
  }

  .text-gwr {
    font-size: 21px;
  }

  .logo-ind {
    height: 50px;
    width: 50px;
  }

  .b-text {
    font-size: 18px;
  }

  .bottom-text-gwr {
    font-size: 18px;
    line-height: 18px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-gwr {
    height: 250px;
    padding: 12px;
  }

  .img-gwr {
    width: 55%;
  }

  .text-gwr {
    font-size: 21px;
  }

  .logo-ind {
    height: 45px;
    width: 45px;
  }

  .b-text {
    font-size: 18px;
  }

  .bottom-text-gwr {
    font-size: 18px;
    line-height: 18px;
    margin: 36px 0px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .card-gwr {
    height: 250px;
    .gwr-progress-bar {
      max-width: 150px;
    }
    .gwr-progress-bar .gwr-progress-segment.active {
      width: 75px !important;
    }
    padding: 20px;
  }

  .img-gwr {
    width: 45%;
  }

  .text-gwr {
    font-size: 21px;
  }

  .logo-ind {
    height: 45px;
    width: 45px;
  }

  .b-text {
    font-size: 20px;
  }

  .bottom-text-gwr {
    font-size: 14px;
    line-height: 22px;
    margin: 20px 0px !important;
  }
}

@media only screen and (max-width: 480px) {
  .card-gwr {
    height: 230px;
    padding: 20px;
  }

  .main-text-gwr {
    font-size: 16px;
    line-height: 24px;
  }

  .img-gwr {
    width: 45%;
  }

  .text-gwr {
    font-size: 18px;
  }

  .logo-ind {
    height: 45px;
    width: 45px;
  }

  .b-text {
    font-size: 18px;
  }

  .bottom-text-gwr {
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0px !important;
  }
}
