.social-icon {
    height: 24px;
    width: 24px;
  }
  .social-icon:hover {
    filter: brightness(1.3);
    transform: scale(1.1);
  }
  @media (max-width: 700px) {
    .social-icon {
      height: 20px;
      width: 20px;
    }
  }
  