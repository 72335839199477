.hireexexperts-container {
  max-width: 100%;
  margin: 0 105px;
}
/* #hire-ex-experts {
  border-bottom: 2px solid transparent; 
  border-image: linear-gradient(
      to right,
      rgba(251, 250, 255, 0) 0%,
      rgba(53, 96, 243, 1) 44%,
      rgba(113, 4, 188, 1) 57%,
      rgba(251, 250, 255, 0) 98%
    ) 
} */
.slide-container-he {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 60px;
}
.image-row-he {
  display: flex;
  align-items: center;
  gap: 2%;
}

.Hireximagecontainer {
  position: relative;
  width: 15%;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hireximagecontainer:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.hireexexperts-progress-bar {
  position: relative;
  width: 100%;
  max-width: 160px;
  height: 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.hireexexperts-progress-bar .hireexexperts-progress-segment {
  height: 100%;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
}

.hireexexperts-progress-bar .hireexexperts-progress-segment.active {
  background-color: #5928d1;
  width: 80px !important;
  cursor: default;
}

.Hireximagecontainer img {
  position: absolute;
  transform: scale(0.9);
}
.Hireximagecontainer .bg-4 {
  width: 186px;
  height: 44px;
  object-position: -451px -758px;
  object-fit: none;
}

.Hireximagecontainer .bg-1 {
  width: 160px;
  height: 50px;
  object-position: -882px -520px;
  object-fit: none;
}

.Hireximagecontainer .bg-2 {
  width: 238px;
  height: 44px;
  object-position: -10px -470px;
  object-fit: none;
}

.Hireximagecontainer .bg-3 {
  width: 193px;
  height: 50px;
  object-position: -365px -608px;
  object-fit: none;
}

.Hireximagecontainer .bg-5 {
  width: 238px;
  height: 36px;
  object-position: -258px -698px;
  object-fit: none;
}

.Hireximagecontainer .bg-6 {
  width: 100px;
  height: 100px;
  object-position: -882px -90px;
  object-fit: none;
}

.Hireximagecontainer .bg-8 {
  width: 238px;
  height: 60px;
  object-position: -247px -10px;
  object-fit: none;
}

.Hireximagecontainer .bg-7 {
  width: 183px;
  height: 50px;
  object-position: -10px -824px;
  object-fit: none;
}

.Hireximagecontainer .bg-9 {
  width: 164px;
  height: 70px;
  object-position: -505px -89px;
  object-fit: none;
}

.Hireximagecontainer .bg-10 {
  width: 100px;
  height: 100px;
  object-position: -882px -210px;
  object-fit: none;
}

.Hireximagecontainer .bg-11 {
  width: 224px;
  height: 50px;
  object-position: -230px -280px;
  object-fit: none;
}

.Hireximagecontainer .bg-12 {
  width: 191px;
  height: 59px;
  object-position: -505px -10px;
  object-fit: none;
}

.Hireximagecontainer .bg-13 {
  width: 185px;
  height: 60px;
  object-position: -505px -179px;
  object-fit: none;
}

.Hireximagecontainer .bg-14 {
  width: 139px;
  height: 90px;
  object-position: -10px -360px;
  object-fit: none;
}

.Hireximagecontainer .bg-15 {
  width: 178px;
  height: 80px;
  object-position: -10px -100px;
  object-fit: none;
}

.Hireximagecontainer .bg-16 {
  width: 167px;
  height: 50px;
  object-position: -606px -824px;
  object-fit: none;
}

.Hireximagecontainer .bg-17 {
  width: 238px;
  height: 38px;
  object-position: -268px -470px;
  object-fit: none;
}

.Hireximagecontainer .bg-18 {
  width: 200px;
  height: 60px;
  object-position: -10px -280px;
  object-fit: none;
}

.Hireximagecontainer .bg-19 {
  width: 198px;
  height: 40px;
  object-position: -657px -758px;
  object-fit: none;
}

.Hireximagecontainer .bg-20 {
  width: 238px;
  height: 34px;
  object-position: -516px -698px;
  object-fit: none;
}

.Hireximagecontainer .bg-21 {
  width: 204px;
  height: 46px;
  object-position: -10px -758px;
  object-fit: none;
}

.Hireximagecontainer .bg-22 {
  width: 217px;
  height: 70px;
  object-position: -10px -10px;
  object-fit: none;
}

.Hireximagecontainer .bg-23 {
  width: 104px;
  height: 100px;
  object-position: -716px -100px;
  object-fit: none;
}

.Hireximagecontainer .bg-24 {
  width: 154px;
  height: 70px;
  object-position: -505px -259px;
  object-fit: none;
}

.Hireximagecontainer .bg-25 {
  width: 117px;
  height: 70px;
  object-position: -882px -590px;
  object-fit: none;
}

.Hireximagecontainer .bg-26 {
  width: 238px;
  height: 38px;
  object-position: -228px -534px;
  object-fit: none;
}

.Hireximagecontainer .bg-27 {
  width: 96px;
  height: 90px;
  object-position: -882px -410px;
  object-fit: none;
}

.Hireximagecontainer .bg-28 {
  width: 120px;
  height: 90px;
  object-position: -505px -349px;
  object-fit: none;
}

.Hireximagecontainer .bg-29 {
  width: 238px;
  height: 54px;
  object-position: -208px -100px;
  object-fit: none;
}

.Hireximagecontainer .bg-30 {
  width: 173px;
  height: 50px;
  object-position: -413px -824px;
  object-fit: none;
}

.Hireximagecontainer .bg-31 {
  width: 146px;
  height: 60px;
  object-position: -882px -330px;
  object-fit: none;
}

.Hireximagecontainer .bg-32 {
  width: 217px;
  height: 60px;
  object-position: -10px -200px;

  object-fit: none;
}

.Hireximagecontainer .bg-33 {
  width: 212px;
  height: 60px;
  object-position: -247px -200px;
  object-fit: none;
}

.Hireximagecontainer .bg-34 {
  width: 197px;
  height: 44px;
  object-position: -234px -758px;
  object-fit: none;
}

.Hireximagecontainer .bg-35 {
  width: 198px;
  height: 54px;
  object-position: -10px -534px;
  object-fit: none;
}

.Hireximagecontainer .bg-36 {
  width: 146px;
  height: 70px;
  object-position: -716px -10px;
  object-fit: none;
}

.Hireximagecontainer .bg-37 {
  width: 161px;
  height: 60px;
  object-position: -882px -10px;
  object-fit: none;
}

.Hireximagecontainer .bg-38 {
  width: 220px;
  height: 50px;
  object-position: -169px -360px;
  object-fit: none;
}

.Hireximagecontainer .bg-39 {
  width: 180px;
  height: 50px;
  object-position: -213px -824px;
  object-fit: none;
}

.Hireximagecontainer .bg-40 {
  width: 228px;
  height: 40px;
  object-position: -10px -698px;
  object-fit: none;
}

.Hireximagecontainer .bg-41 {
  width: 167px;
  height: 60px;
  object-position: -178px -608px;
  object-fit: none;
}

.Hireximagecontainer .bg-42 {
  width: 148px;
  height: 70px;
  object-position: -10px -608px;
  object-fit: none;
}
@media (min-width: 2000px) {
  .Hireximagecontainer img {
    transform: scale(1);
  }
}
@media (max-width: 1680px) {
  .Hireximagecontainer img {
    transform: scale(0.7);
  }
}
@media (max-width: 1360px) {
  .Hireximagecontainer img {
    transform: scale(0.56);
  }
}
@media (max-width: 1339px) {
  .Hireximagecontainer {
    height: 90px;
  }
}

@media (max-width: 1024px) {
  .hireexexperts-container {
    margin: 0 50px;
  }

  .Hireximagecontainer {
    height: 80px;
  }
}

@media (max-width: 768px) {
  .hireexexperts-container {
    margin: 0 50px;
  }
  .slide-container-he {
    margin-bottom: 40px;
  }

  .Hireximagecontainer {
    height: 70px;
    width: 24%;
  }

  .image-row-he {
    gap: 1.33%;
  }
}

@media (max-width: 767px) {
  .hireexexperts-container {
    margin: 0 26px;
  }
}
@media (max-width: 1150px) {
  .Hireximagecontainer img {
    transform: scale(0.52);
  }
}
@media (max-width: 960px) {
  .Hireximagecontainer img {
    transform: scale(0.4);
  }
}
@media (max-width: 768px) {
  .Hireximagecontainer img {
    transform: scale(0.55);
  }
}
@media (max-width: 630px) {
  .Hireximagecontainer img {
    transform: scale(0.50);
  }
}
@media (max-width: 580px) {
  .Hireximagecontainer img {
    transform: scale(0.4);
  }
}
@media (max-width: 480px) {
  .hireexexperts-container {
    margin: 0 16px;
  }

  .image-row-he {
    gap: 5%;
  }

  .Hireximagecontainer {
    height: 50px;
    width: 30%;
  }
  .Hireximagecontainer img {
    transform: scale(0.45);
  }
  .Hireximagecontainer .bg-28 {
    transform: scale(0.5);
  }
  .Hireximagecontainer .bg-30 {
    transform: scale(0.5);
  }
}
@media (max-width: 420px) {
  .Hireximagecontainer img {
    transform: scale(0.4);
  }
}
@media (max-width: 380px) {
  .Hireximagecontainer img {
    transform: scale(0.32);
  }
}
@media (max-width: 320px) {
  .Hireximagecontainer .bg-2 {
    transform: scale(0.32);
  }
  .Hireximagecontainer .bg-5 {
    transform: scale(0.32);
  }
  .Hireximagecontainer .bg-8 {
    transform: scale(0.32);
  }
  .Hireximagecontainer .bg-20 {
    transform: scale(0.31);
  }
  .Hireximagecontainer .bg-26 {
    transform: scale(0.32);
  }
}