.navbar-new-link {
  gap: 32px;
  display: none;
}

.navbar-new-link {
  color: white;
}
.navbar-new-link .dropdown .link {
  font-size: 16px;
  font-weight: 500;
}
.navbar-new-link .dropdown .link:hover {
  color: white;
}
.header-appear .navbar-new-link .dropdown .link:hover {
  color: #5928d1;
}
.header-appear .navbar-new-link {
  color: black;
}
/* General dropdown hover */
.navbar-new-link .dropdown-menu {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.navbar-new-link .dropdown-item {
  color: #000;
  transition: background-color 0.3s ease;
}

/* Hover effect for the dropdown */
.navbar-new-link .dropdown-item:hover {
  background-color: #e6bfff;
  color: black;
}
.navbar-new-link .dropdown-menu {
  padding: 0;
}
.navbar-new-link .dropdown-menu .dropdown-item {
  height: 59px;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.8rem;
}
#haveto {
  scroll-margin-top: 7.5rem;
}

#fxchat {
  scroll-margin-top: 7.5rem;
}
#aicoder {
  scroll-margin-top: 7.5rem;
}

#aitutor {
  scroll-margin-top: 7.5rem;
}
.rotate-icon {
  transition: transform 0.3s ease;
}

.rotate {
  transform: rotate(-180deg);
}
@media (min-width: 1439px) {
  .dropdown .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
@media (max-width: 1439px) {
  .navbar-new-link {
    gap: 16px;
  }
  .navbar-new-link .dropdown .link {
    font-size: 14px;
    font-weight: 500;
  }
}
@media (max-width: 1600px) {
  .navbar-new-link {
    gap: 16px;
  }
}
@media (max-width: 1024px) {
  .navbar-mob-new-link {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar-mob-new-link .link {
    color: white;
  }
  .navbar-mob-new-link .dropdown {
    padding: 24px 10px;
  }
  .navbar-mob-new-link .dropdown .link {
    font-size: 20px;
    font-weight: 300;
  }
  .navbar-mob-new-link .dropdown-menu .dropdown-item {
    height: 59px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
  }
  .navbar-mob-new-link .dropdown-menu {
    padding: 0;
    width: 250px;
  }
}
