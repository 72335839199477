.sidemenu_btn.left {
  left: 15px;
  right: auto;
}

.button-modal{
  margin-left: 32px;
}

@media (max-width: 1600px) {
  .button-modal{
    margin-left: 16px;
  }
}
.sidemenu_btn span {
  height: 2px;
  width: 100%;
  background: #000;
  display: block;
}

.navbar-dark .sidemenu_btn span {
  background: #151515;
}

.navbar-box .sidemenu_btn {
  display: none !important;
}

.header-appear .navbar-box .sidemenu_btn {
  display: inline-block !important;
}

.header-appear .navbar-side .sidemenu_btn span {
  background: #151515;
}

.header-appear .navbar-simple .sidemenu_btn span {
  background: #666;
}

.header-appear .navbar-gradient .sidemenu_btn span {
  background: #fff;
}

.sidemenu_btn span:nth-child(2) {
  margin: 4px 10px;
}

.sidemenu_btn {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  width: 36px;
  padding: 6px;
  margin-right: 1rem;
  position: absolute;
  right: 15px;
  display: inline-block;
}

.sidemenu_btn.parallax-btn {
  width: 70px;
}

.sidemenu_btn_inner {
  height: 70px;
  width: 70px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  float: left;
}

.sidemenu_btn_inner .animated-element {
  width: 25px;
  text-align: center;
}

.sidemenu_btn span {
  height: 2px;
  width: 100%;
  background-color: #fff;
  display: block;
}

.sidemenu_btn.cursor-pointer {
  cursor: pointer;
}

.privacy-bar {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 991px) {
  .sidemenu_btn {
    position: relative;
    right: 0;
    margin: 0;
  }

  .navbar-box .sidemenu_btn {
    display: inline-block !important;
    margin: 0;
  }

  .sidemenu_btn.parallax-btn {
    width: 45px;
  }

  .sidemenu_btn_inner {
    height: 35px;
    width: 35px;
  }
}

@media screen and (max-width: 767px) {
  .sidemenu_btn {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .sidemenu_btn.parallax-btn {
    width: 100px;
  }
}
@media (min-width: 1281px) {
  .css-vpbpzp-control {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .css-1xc3v61-indicatorContainer {
    padding: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .navUi {
    display: none !important;
  }
  .show-hide-burger {
    display: inline-block !important;
  }
}
@media (max-width: 800px) {
  .language-selector-wrapper {
    display: none;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .sidemenu_btn {
    right: 68px;
  }
}
