.founders-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 80px;
}

#ourfounders {
    border-bottom: 2px solid transparent; /* Set the desired thickness */
   border-image: linear-gradient(to right, rgba(251, 250, 255, 0)0%, rgba(53, 96, 243, 1)44%, rgba(113, 4, 188, 1)57%,rgba(251, 250, 255, 0)98%) 1;
 }

.founders-container {
    display: flex;
    margin: 0 105px;
    border-radius: 16px;
    gap: 48px;
}

.schedule-meeting-button{
    background-color: #5928d1;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0px 12px;
    border-radius: 8px;
    font-weight: 500;
    border: 2px solid #5928d1;
    font-size: 14px;
   

}

.founders-container.row {
    flex-direction: row;
}

.founders-container.row-reverse {
    flex-direction: row-reverse;
}

.founder-image-container {
    width: 450px;
    height: 465px;
}

.founder-image {
    width: 100%;
    height: 100%;
}

.founder-info {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row-reverse .founder-info-box {
    text-align: right;
}

.row-reverse .founder-info h3 {
    text-align: left;
}

.desc-box {
    margin-bottom: 50px;
}
#quotebox img{
    width: 66px;
    height: 44px;
}
.desc-box .quotebox2 {
    display: flex;
    justify-content: flex-end;
}

.founder-info-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.founder-name {
    font-size: 36px;
    color: #2D2D2D;
    font-weight: 600;
}

.founder-title {
    font-size: 18px;
    color: #484848;
    font-weight: 400;
}

.founder-description {
    color: #343a40;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
    padding: 12px 0 12px 0;
}

.founder-social-media-icon {
    display: flex;
    gap: 12px;

}

.row-reverse .founder-social-media-icon {
    justify-content: flex-end;
}

.social2-icon {
    width: 40px;
    height: 40px;
}

.social2-icon:hover {
    filter: brightness(1.3);
    transform: scale(1.1);
}
@media (min-width: 1024px) and (max-width: 1241px) {
    .founder-image-container {
        width: 380px;
        height: 500px;
    }
    .founders-container {
        gap: 32px;
    }
    #quotebox img{
        width: 33px;
        height: 22px;
    }
}
@media (min-width: 1024px) and (max-width: 1450px) {

    .desc-box {
        margin-bottom: 24px;
    }

    .founder-info-box {
        gap: 8px;
    }

    .founder-name {
        font-size: 32px;
    }

    .founder-description {
        line-height: 24px;
    }

    .founder-title {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .founders-container {
        margin: 0 50px;
    }
    .founder-description {
        padding: 12px 0 12px 0;
    }
}

@media (max-width: 1023px) {

    .founders-container,
    .founders-container.row,
    .founders-container.row-reverse {
        flex-direction: column;
        gap: 0;
    }

    .founder-info,
    .row-reverse .founder-info {
        text-align: center;
        flex-direction: column-reverse;
        padding: 0;
    }

    .founder-image-container {
        width: 100%;
        height: auto;
    }

    .founder-info-box {
        padding: 48px 0;
        gap: 6px;
    }
    .quotebox1 {
        display: flex;
        justify-content: flex-start;
    }
    .desc-box {
        margin-bottom: 0;
    }

    .founder-social-media-icon,
    .row-reverse .founder-social-media-icon {
        justify-content: center;
    }

    .social2-icon,
    .row-reverse .social2-icon {
        margin: 0 7.5px;
    }
    .row-reverse .founder-info-box {
        text-align: center;
    }
}
@media (max-width: 767px) {
    .founders-container,
    .founders-container.row,
    .founders-container.row-reverse {
        margin: 0 24px;
    }
}
@media (max-width: 480px) {

    .founders-container,
    .founders-container.row,
    .founders-container.row-reverse {
        margin: 0 16px;
        border-radius: 8px;
    }

    .founder-info-box {
        padding: 24px 0;
        gap: 8px;
    }

    .founder-description {
        font-weight: 400;
        line-height: 26px;
        padding: 10px 0 10px 0;
        text-align: center;
    }
    .row-reverse .founder-info h3 {
        text-align: center;
    }
    .founder-name {
        font-size: 24px;
    }
    #quotebox img{
        width: 33px;
        height: 22px;
    }
}