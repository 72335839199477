.custom-modal-dialog {
  justify-content: center;
}
.custom-modal-dialog .modal-content {
  width: 426px;
  height: 352px;
  text-align: center;
}
.custom-modal-dialog .modal-content .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 60%;
}
.success-tag {
    color: #575757;
    font-weight: 600;
    font-size: 32px;
}
.success-msg {
    width: 100%;
    color: #898989;
    font-size: 16px;
    font-weight: 400;
    text-wrap: wrap;
}
.success-modal-submitbtn {
  width: 75%;
}
