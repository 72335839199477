.card-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
  margin: 0 120px;
}
.custom-card {
  width: 23.5%;
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  padding: 20px;
  position: relative;
  border-radius: 16px;
  gap: 40px;
  overflow: hidden;
  transition: transform 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  background-color: white;
}

.custom-card:hover {
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3); 
}

.custom-card p {
  margin-bottom: 0px !important;
}

@media ( min-width: 2302px){
  .custom-card {
    height: 345px !important;
  }
}

@media ( min-width: 1948px) and (max-width: 2301px) {
  .custom-card {
    height: 375px !important;
  }
}

@media ( min-width: 1773px) and (max-width: 1947px) {
  .custom-card {
    height: 400px !important;
  }
}

@media ( min-width: 1537px) and (max-width: 1772px) {
  .custom-card {
    height: 430px !important;
  }
}

@media ( min-width: 1462px) and (max-width: 1536px) {
  .custom-card {
    height: 455px !important;
  }
}

@media ( min-width: 1441px) and (max-width: 1461px) {
  .custom-card {
    height: 485px !important;
  }
}

@media ( min-width: 1252px) and (max-width: 1440px) {
  .custom-card {
    height: 345px !important;
  }
}

@media ( min-width: 1076px) and (max-width: 1251px) {
  .custom-card {
    height: 375px !important;
  }
}

@media ( min-width: 1025px) and (max-width: 1075px) {
  .custom-card {
    height: 400px !important;
  }
}

@media ( min-width: 707px) and (max-width: 1024px) {
  .custom-card {
    height: 315px !important;
  }
}

@media ( min-width: 566px) and (max-width: 706px) {
  .custom-card {
    height: 350px !important;
  }
}

@media ( min-width: 482px) and (max-width: 565px) {
  .custom-card {
    height: 380px !important;
  }
}

@media ( min-width: 462px) and (max-width: 481px) {
  .custom-card {
    height: 355px !important;
  }
}

@media ( min-width: 426px) and (max-width: 461px) {
  .custom-card {
    height: 380px !important;
  }
}

@media ( min-width: 424px) and (max-width: 425px) {
  .custom-card {
    height: 330px !important;
  }
}

@media ( min-width: 388px) and (max-width: 423px) {
  .custom-card {
    height: 350px !important;
  }
}

@media ( min-width: 341px) and (max-width: 387px) {
  .custom-card {
    height: 375px !important;
  }
}

@media ( min-width: 337px) and (max-width: 340px) {
  .custom-card {
    height: 385px !important;
  }
}

@media ( max-width: 336px){
  .custom-card {
    height: 400px !important;
  }
}

.icon-container{
  width: 100%;
  justify-content: center;
  align-items: center;
 
}
.card-icon{
  width: 80px;
  height: 80px;
}

.ind-title{
  font-size: 28px !important;
  font-weight: 600;
  color: #484848;
}
.ind-description{
  font-size: 18px !important;
  line-height: 28px !important;
  font-weight: 400;
  color: #343a40;
}



@media (min-width: 2000px) {
  .card-row {
    margin: 0 118px;
  }
}


@media (max-width: 1440px) {
  .custom-card {
    width: 23.5%;
   
  }
  .ind-title{
    font-size: 24px !important;
  }
  .ind-description{
    font-size: 18px !important;
    line-height: 28px !important;
  }

}
@media ( min-width: 1281px) and (max-width: 1440px) {
  .custom-card {
    width: 49%;
  }
  .card-row {
    margin: 0 120px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .card-row {
    margin: 0 120px;
    justify-content: space-between;
  }
  .custom-card {
    width: 48%;
  }
  .ind-title{
    font-size: 28px !important;
  }
  .ind-description{
    font-size: 18px !important; 
    line-height: 28px !important;
  }
}

@media (max-width: 1024px) {
  .custom-card {
    width: 100%;
  }
  .card-row {
    margin: 0 65px;
  }
  .ind-title{
    font-size: 28px !important;
  }
  .ind-description{
    font-size: 18px !important; 
    line-height: 28px !important;
  }
}

@media (max-width: 768px) {
  .card-row {
    margin: 0 65px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .card-row {
    flex-direction: column;
    margin: 0 40px;
  }
  .card-icon{
    width: 75px;
    height: 75px;
  }
}

@media (max-width: 481px) {
  .card-row {
    margin: 0 30px;
    flex-direction: column;
  }
  .card-icon{
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 425px) {
  .ind-title{
    font-size: 24px !important;
  }
  .ind-description{
    font-size: 16px !important; 
    line-height: 24px !important;
  }
}

