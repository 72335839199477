.expertise__content-wrapper {
  display: flex;
  justify-content: flex-start;
}
/* #areaexpertise {
  border-bottom: 2px solid transparent; 
 border-image: linear-gradient(to right, rgba(251, 250, 255, 0)0%, rgba(53, 96, 243, 1)44%, rgba(113, 4, 188, 1)57%,rgba(251, 250, 255, 0)98%) 1;
} */
.expertise__cards-container {
  width: 100%;
  display: flex;
  margin: 0 135px;
  gap: 2%;
  flex-wrap: wrap;
}
.expertise-column {
  background-color: #fbfaff;
  padding: 40px;
  width: 23.5%;
  border-radius: 16px;
}
.expertise-column:hover {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3); 
}
.expertise-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #484848;
  text-align: center;
}
.expertise-name {
  font-size: 18px;
  text-align: left !important;
  font-weight: 400 !important;
  color: #343a40;
}
.expertise-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.expertise-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.expertise-item img {
  width: 40px;
  height: 40px;
}
.areaImageContainer {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.areaImageContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(0.26);
}
@media (max-width: 1439px) {

  .expertise-column {
    width: 48.5%;
  }
  .expertise__cards-container {
    gap: 20px;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .expertise-title {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .expertise__cards-container {
    margin: 0 80px;
  }

  .expertise-column {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .expertise__cards-container {
    margin: 0 55px;
  }

  .expertise-column {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .expertise__cards-container {
    margin: 0 46px;
  }
  .expertise-title {
    font-size: 24px;
  }
  
  .expertise-name {
    font-size: 16px;
  }
}
.areaImageContainer .bg-1 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -10px -10px;
}

.areaImageContainer .bg-2 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -190px -10px;
}

.areaImageContainer .bg-3 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -10px -190px;
}

.areaImageContainer .bg-4 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -190px -190px;
}

.areaImageContainer .bg-5 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -370px -10px;
}

.areaImageContainer .bg-6 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -370px -190px;
}

.areaImageContainer .bg-7 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -10px -370px;
}

.areaImageContainer .bg-8 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -190px -370px;
}

.areaImageContainer .bg-9 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -370px -370px;
}

.areaImageContainer .bg-10 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -550px -10px;
}

.areaImageContainer .bg-11 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -550px -190px;
}

.areaImageContainer .bg-12 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -550px -370px;
}

.areaImageContainer .bg-13 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -10px -550px;
}

.areaImageContainer .bg-14 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -190px -550px;
}

.areaImageContainer .bg-15 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -370px -550px;
}

.areaImageContainer .bg-16 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -550px -550px;
}

.areaImageContainer .bg-17 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -730px -10px;
}

.areaImageContainer .bg-18 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -730px -190px;
}

.areaImageContainer .bg-19 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -730px -370px;
}

.areaImageContainer .bg-20 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -730px -550px;
}

.areaImageContainer .bg-21 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -10px -730px;
}

.areaImageContainer .bg-22 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -190px -730px;
}

.areaImageContainer .bg-23 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -370px -730px;
}

.areaImageContainer .bg-24 {
  width: 154px; height: 154px;
  object-fit: none;
  object-position:  -550px -730px;
}