.service-container {
  width: 100%;
}
/* #services-we-offer {
  border-bottom: 2px solid transparent; 
 border-image: linear-gradient(to right, rgba(251, 250, 255, 0)0%, rgba(53, 96, 243, 1)44%, rgba(113, 4, 188, 1)57%,rgba(251, 250, 255, 0)98%) 1;
} */

.services__content-wrapper {
  display: flex;
  justify-content: center;
}
.services__cards-container {
  display: flex;
  margin: 0 135px;
  justify-content: space-between;
  gap: 1%;
}

.service-card {
  padding: 32px 20px;
  border-radius: 10px;
  width: 24.25%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  /* margin: 10px; */
}

.service-card:hover {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
}

.service-card__title {
  color: #5928d1;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  line-height: 1.2;
}

.service-card__button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px !important;
  font-size: 18px !important;
  color: #ffffff;
  width: 250px;
  background: #5928d1;
}

.service-card__button:hover {
  color: #ffffff;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
}

.service-card__description {
  height: fit-content;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service-card__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

@media (min-width: 1441px)and (max-width: 1747px){
  .service-card {
    height: 545px !important;
  }
}

@media (max-width: 1440px) {
  .service-card {
    width: 32%;
    height: 475px;
  }
  .service-card__title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .service-card__button {
    font-size: 16px !important;
  }
  .service-card__text {
    font-size: 16px;
    line-height: 24px !important;
  }
}

@media (max-width: 1280px) {
  .services__cards-container {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .service-card {
    width: 100%;
    height: fit-content;
  }
  .service-card__title {
    font-size: 28px;
    margin-bottom: 24px;
  }
  .service-card__button {
    font-size: 18px !important;
  }
  .service-card__text {
    font-size: 18px;
    line-height: 28px !important;
    margin-bottom: 40px !important;
  }
}

@media (max-width: 1024px) {
  .services__cards-container {
    flex-wrap: wrap;
    margin: 0px 80px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  .service-card__button {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .services__cards-container {
    flex-wrap: wrap;
    margin: 0px 55px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}

@media (max-width: 480px) {
  .services__cards-container {
    flex-wrap: wrap;
    margin: 0px 46px;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
}

@media (max-width: 425px) {
  .service-card {
    width: 100%;
    height: fit-content;
  }
  .service-card__title {
    font-size: 24px;
  }
  .service-card__button {
    font-size: 16px !important;
  }
  .service-card__text {
    font-size: 16px;
    line-height: 24px !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .alt-font {
    padding: 0 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .alt-font {
    padding: 0 40px;
  }
}
