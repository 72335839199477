.awards-container {
  max-width: 100%;
  margin: 0 105px;
}

/* #awards-and-recognition {
  border-bottom: 2px solid transparent; 
 border-image: linear-gradient(to right, rgba(251, 250, 255, 0)0%, rgba(53, 96, 243, 1)44%, rgba(113, 4, 188, 1)57%,rgba(251, 250, 255, 0)98%) 1;
} */

.slide-container-aw {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 60px;
}

.image-row {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4.76%;
  margin-top: 5px;
}

.Awardsimagecontainer:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.awards-progress-bar {
  position: relative;
  width: 100%;
  max-width: 160px;
  height: 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 8px;
}

.awards-progress-bar .awards-progress-segment {
  height: 100%;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
}

.awards-progress-bar .awards-progress-segment.active {
  background-color: #5928d1;
  width: 80px !important;
  cursor: default;
}

.Awardsimagecontainer {
  position: relative;
  width: 12.7%;
  height: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Awardsimagecontainer img{
  position: absolute;
  transform: scale(0.24);
}

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.9);
}

.Awardsimagecontainer .bg-1 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -5258px -3110px;
}

.Awardsimagecontainer .bg-2 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -1266px;
}

.Awardsimagecontainer .bg-3 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -1266px;
}

.Awardsimagecontainer .bg-4 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -1886px;
}

.Awardsimagecontainer .bg-5 {
  width: 855px; height: 604px;
  object-fit: none;
  object-position:  -10px -642px;
}

.Awardsimagecontainer .bg-6 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -1886px;
}

.Awardsimagecontainer .bg-7 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2633px -10px;
}

.Awardsimagecontainer .bg-8 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2633px -630px;
}

.Awardsimagecontainer .bg-9 {
  width: 855px; height: 604px;
  object-fit: none;
  object-position:  -1758px -10px;
}

.Awardsimagecontainer .bg-10 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2633px -1250px;
}

.Awardsimagecontainer .bg-11 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2633px -1870px;
}

.Awardsimagecontainer .bg-12 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -5258px -3730px;
}

.Awardsimagecontainer .bg-13 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -2506px;
}

.Awardsimagecontainer .bg-14 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -2506px;
}

.Awardsimagecontainer .bg-15 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -1760px -2506px;
}

.Awardsimagecontainer .bg-16 {
  width: 855px; height: 604px;
  object-fit: none;
  object-position:  -1758px -634px;
}

.Awardsimagecontainer .bg-17 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3508px -10px;
}

.Awardsimagecontainer .bg-18 {
  width: 854px; height: 608px;
  object-fit: none;
  object-position:  -884px -10px;
}

.Awardsimagecontainer .bg-19 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3508px -630px;
}

.Awardsimagecontainer .bg-20 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3508px -1250px;
}

.Awardsimagecontainer .bg-21 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3508px -1870px;
}

.Awardsimagecontainer .bg-22 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3508px -2490px;
}

.Awardsimagecontainer .bg-23 {
  width: 854px; height: 612px;
  object-fit: none;
  object-position:  -10px -10px;
}

.Awardsimagecontainer .bg-24 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -3126px;
}

.Awardsimagecontainer .bg-25 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -3126px;
}

.Awardsimagecontainer .bg-26 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -1760px -3126px;
}

.Awardsimagecontainer .bg-27 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2635px -3126px;
}

.Awardsimagecontainer .bg-28 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -3746px;
}

.Awardsimagecontainer .bg-29 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -5258px -4350px;
}

.Awardsimagecontainer .bg-30 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -3746px;
}

.Awardsimagecontainer .bg-31 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -1760px -3746px;
}

.Awardsimagecontainer .bg-32 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2635px -3746px;
}

.Awardsimagecontainer .bg-33 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -10px;
}

.Awardsimagecontainer .bg-34 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -10px -4986px;
}

.Awardsimagecontainer .bg-35 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -630px;
}

.Awardsimagecontainer .bg-36 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -1250px;
}

.Awardsimagecontainer .bg-37 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -1870px;
}

.Awardsimagecontainer .bg-38 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -2490px;
}

.Awardsimagecontainer .bg-39 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -3110px;
}

.Awardsimagecontainer .bg-40 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -884px -4986px;
}

.Awardsimagecontainer .bg-41 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -4383px -3730px;
}

.Awardsimagecontainer .bg-42 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -10px -4366px;
}

.Awardsimagecontainer .bg-43 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -885px -4366px;
}

.Awardsimagecontainer .bg-44 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -1760px -4366px;
}

.Awardsimagecontainer .bg-45 {
  width: 214px; height: 150px;
  object-fit: none;
  object-position:  -1355px -642px;
}

.Awardsimagecontainer .bg-46 {
  width: 214px; height: 150px;
  object-fit: none;
  object-position:  -885px -812px;
}

.Awardsimagecontainer .bg-47 {
  width: 215px; height: 150px;
  object-fit: none;
  object-position:  -885px -642px;
}

.Awardsimagecontainer .bg-48 {
  width: 214px; height: 150px;
  object-fit: none;
  object-position:  -1119px -812px;
}

.Awardsimagecontainer .bg-49 {
  width: 215px; height: 150px;
  object-fit: none;
  object-position:  -1120px -642px;
}

.Awardsimagecontainer .bg-50 {
  width: 214px; height: 150px;
  object-fit: none;
  object-position:  -1353px -812px;
}

.Awardsimagecontainer .bg-51 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -1758px -4986px;
}

.Awardsimagecontainer .bg-52 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -2635px -4366px;
}

.Awardsimagecontainer .bg-53 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -3510px -4366px;
}

.Awardsimagecontainer .bg-54 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -5258px -10px;
}

.Awardsimagecontainer .bg-55 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -5258px -630px;
}

.Awardsimagecontainer .bg-56 {
  width: 854px; height: 600px;
  object-fit: none;
  object-position:  -2632px -4986px;
}

.Awardsimagecontainer .bg-57 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -5258px -1250px;
}

.Awardsimagecontainer .bg-58 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -5258px -1870px;
}

.Awardsimagecontainer .bg-59 {
  width: 855px; height: 600px;
  object-fit: none;
  object-position:  -5258px -2490px;
}

@media (min-width: 2000px) {
  .Awardsimagecontainer {
    height: 250px;
  }

}

@media (max-width: 1800px) {
  .Awardsimagecontainer img{
    transform: scale(0.2);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.8);
}
}

@media (max-width: 1550px) {
  .Awardsimagecontainer img{
    transform: scale(0.18);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.7);
}
}

@media (max-width: 1440px) {
  .Awardsimagecontainer {
    height: 140px;
  }
}

@media (max-width: 1420px) {
  .Awardsimagecontainer img{
    transform: scale(0.16);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.6);
}
}

@media (max-width: 1300px) {
  .Awardsimagecontainer img{
    transform: scale(0.14);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.5);
}
}

@media (max-width: 1150px) {
  .Awardsimagecontainer img{
    transform: scale(0.12);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.48);
}
}

@media (max-width: 1280px) {
  .Awardsimagecontainer {
    height: 125px;
  }
}

@media (max-width: 1024px) {
  .awards-container {
    margin: 0 50px;
  }

  .Awardsimagecontainer {
    height: 100px;
  }
}

@media (max-width: 900px) {
  .Awardsimagecontainer img{
    transform: scale(0.1);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.4);
}
}


@media (max-width: 768px) {
  .slide-container-aw {
    gap: 40px;
    margin-bottom: 40px;
  }

  .image-row {
    gap: 4%;
  }

  .Awardsimagecontainer {
    width: 22%;
    height: 90px;
  }

  .Awardsimagecontainer img{
    transform: scale(0.15);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.6);
}
}

@media (max-width: 767px) {
  .awards-container {
    margin: 0 25px;
  }
}

@media (max-width: 660px) {
  .Awardsimagecontainer img{
    transform: scale(0.13);
  }

  .Awardsimagecontainer .bg-45,
  .Awardsimagecontainer .bg-46,
  .Awardsimagecontainer .bg-47,
  .Awardsimagecontainer .bg-48,
  .Awardsimagecontainer .bg-49,
  .Awardsimagecontainer .bg-50 {
    transform: scale(0.53);
  }

  .Awardsimagecontainer {
    height: 86px;
  }
}

@media (max-width: 550px) {
  .Awardsimagecontainer img{
    transform: scale(0.11);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.43);
}
}

@media (max-width: 480px) {
  .awards-container {
    margin: 0 16px;
  }

  .slide-container-aw {
    gap: 24px;
  }

  .image-row {
    gap: 5%;
  }

  .Awardsimagecontainer {
    width: 30%;
    height: 80px;
  }
}

@media (max-width: 375px) {
  .Awardsimagecontainer img{
    transform: scale(0.1);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.4);
}
}

@media (max-width: 320px) {
  .Awardsimagecontainer img{
    transform: scale(0.09);
  }

.Awardsimagecontainer .bg-45,
.Awardsimagecontainer .bg-46,
.Awardsimagecontainer .bg-47,
.Awardsimagecontainer .bg-48,
.Awardsimagecontainer .bg-49,
.Awardsimagecontainer .bg-50 {
  transform: scale(0.38);
}
}