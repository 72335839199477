.testimonial_slide > .owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
  padding: 2rem;
}

.testimonial-item {
  transition: transform 0.3s ease, filter 0.3s ease;
  height: 370px;
  background-color: white;
}

.testimonial-item.center {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.3);
  transform: scale(1.1);
  filter: blur(0px);
  border-radius: 10px;
}

.testimonial-item.blur {
  /* box-shadow: 0px 2px 8px 8px rgba(89, 40, 209, 0.1); */
  border-radius: 10px;
  /* filter: blur(5px); */
  /* transform: scale(0.9); */
}

.testimonial-item .testimonial-icon {
  font-size: 2.2rem;
  margin-bottom: 2rem;
}

.testimonial-item .testimonial-image {
  position: relative;
  overflow: hidden;
  max-width: 100px;
  margin: 2rem auto 1.5rem auto;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}

.testimonial-item .destination {
  font-size: 13px;
  font-weight: 400;
  color: #9c9c9c;
}
.testimonial-progress-bar {
  position: relative;
  width: 100%;
  max-width: 160px;
  height: 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 8px;
}

.testimonial-progress-bar .testimonial-progress-segment {
  height: 100%;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
}

.testimonial-progress-bar .testimonial-progress-segment.active {
  background-color: #5928d1;
  width: 80px !important;
  cursor: default;
}

.testimonial-card {
  border-radius: 10px;
  border: none;
  justify-content: center;
}
.testimonial-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testimonial-card-title {
  color: #5928d1;
  font-weight: 600;
  font-size: 20px !important;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.2 !important;
}
.testimonial-card-text {
  color: #343a40;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0px !important;
}
.testimonial-logo {
  width: 80px;
  height: 55px;
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* @media (max-width: 1400px) {
  .testimonial-item {
    height: 400px !important;
  }
} */
@media (min-width: 1200px) {
  .testimonial-item {
    transition: transform 0.3s ease, filter 0.3s ease;
    margin: 0 40px 0 40px !important;
  }
}
@media (max-width: 1200px) {
  .testimonial-item {
    height: 410px !important;
  }
  .testimonial-card {
    height: 100% !important;
  } 
}
@media (max-width: 1024px) {
  .testimonial-item {
    height: 430px !important;
  }
  .testimonial-card {
    height: 100% !important;
  } 
}
@media (max-width: 768px) {
  .testimonial-item.center {
    box-shadow: 0px 2px 8px 8px rgba(89, 40, 209, 0.1); /* Adjust these values for a bigger shadow */
    transform: scale(1);
    filter: blur(0px);
  }
  .testimonial-item {
    height: 590px !important;
    margin: 0 60px !important;
  }
  .testimonial-card {
    height: 100% !important;
  }
  .testimonial-card-text {
    font-size: 20px;
    line-height: 1.4 !important
  }
  .testimonial-card-title {
    font-size: 24px !important;
    line-height: 1.2 !important;
  }
}
@media (max-width: 700px) {
  .testimonial-item {
   margin: 0 !important;
  }
  .testimonial-card-text {
    font-size: 18px;
    line-height: 1.5 !important;
  }
  .testimonial-card-title {
    font-size: 22px !important;
  }
}
@media (max-width: 425px) {
  .testimonial-item {
    height: 508px !important;
    margin: 0 !important;
  }
  .testimonial-card {
    width: 350px !important;
    min-height: 503px !important;
    border: none !important;
  }
  .testimonial-card-text {
    font-size: 16px;
    line-height: 1.5 !important;
  }
  .testimonial-card-title {
    font-size: 16px !important;
  }
}

@media (max-width: 375px) {
  .testimonial-item {
    height: 580px !important;
  }
  .testimonial-card {
    height: 0 !important;
    width: 350px !important;
    min-height: 590px !important;
    border: none !important;
  }
}
@media (max-width: 374px) {
  .testimonial-item {
    height: 650px !important;
  }
  .testimonial-card {
    height: 100%;
  }
}
.toggle-link {
  color: #5928D1;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  margin-top: 0.5em;
  font-weight: 600;
}
.TestimonialImageContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 55px;
  object-fit: cover;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.TestimonialImageContainer img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) scale(0.2);
}
.TestimonialImageContainer .bg-1 {
  width: 310px; height: 141px;
  object-position:  -1605px -422px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.25) !important;
}

.TestimonialImageContainer .bg-2 {
  width: 416px; height: 94px;
  object-position:  -1401px -658px;
  object-fit: none;
}

.TestimonialImageContainer .bg-3 {
  width: 955px; height: 504px;
  object-position:  -10px -10px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.1) !important;
}

.TestimonialImageContainer .bg-4 {
  width: 186px; height: 103px;
  object-position:  -1690px -824px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.4) !important;
}

.TestimonialImageContainer .bg-5 {
  width: 200px; height: 195px;
  object-position:  -985px -824px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.3) !important;
}

.TestimonialImageContainer .bg-6 {
  width: 143px; height: 88px;
  object-position:  -1837px -658px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.6) !important;
}

.TestimonialImageContainer .bg-7 {
  width: 600px; height: 216px;
  object-position:  -985px -422px;
  object-fit: none;
}

.TestimonialImageContainer .bg-8 {
  width: 220px; height: 104px;
  object-position:  -1450px -824px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.4) !important;
}

.TestimonialImageContainer .bg-9 {
  width: 396px; height: 146px;
  object-position:  -985px -658px;
  object-fit: none;
}

.TestimonialImageContainer .bg-10 {
  width: 147px; height: 70px;
  object-position:  -985px -1039px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.5) !important;
}

.TestimonialImageContainer .bg-11 {
  width: 1000px; height: 228px;
  object-position:  -985px -10px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.1) !important;
}

.TestimonialImageContainer .bg-12 {
  width: 225px; height: 112px;
  object-position:  -1205px -824px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.4) !important;
}

.TestimonialImageContainer .bg-13 {
  width: 170px; height: 33px;
  object-position:  -1605px -583px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.38) !important;
}

.TestimonialImageContainer .bg-14 {
  width: 948px; height: 144px;
  object-position:  -985px -258px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.12) !important;
}

.TestimonialImageContainer .bg-15 {
  width: 650px; height: 600px;
  object-position:  -10px -534px;
  object-fit: none;
  transform: translate(-50%, -50%) scale(0.1) !important;
}