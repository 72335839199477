.latest-articles__container {
  display: flex;
  margin: 0 120px;
  justify-content: space-between;
}

.article-card {
  background-color: #fff;
  border: solid 2px rgba(231, 231, 231, 1);
  border-radius: 24px;
  padding: 22px 24px;
  overflow: hidden;
  width: 23.215%;
}

.article-card:hover {
  border: 2px solid #fff;
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.1);
}

.article-card__image {
  width: 100%;
  height: 350px;
  border-radius: 16px;
  object-fit: cover;
}

.article-card__content {
  text-align: left;
}

.article-card__category {
  background-color: rgba(228, 218, 255, 1);
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 550 !important;
  font-size: 14px;
  color: rgba(89, 40, 209, 1);
  margin-top: 22px;
  width: fit-content;
  margin-bottom: 16px;
}

.article-card__title {
  font-size: 24px;
  color: #484848;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 8px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.article-card:hover .article-card__title {
  color: rgba(89, 40, 209, 1); 
}

/* .article-card__author{
  font-size: 16px;
  color: #343a40;
  font-weight: 500;
} */

.article-card__date{
  display: flex;
  font-size: 18px;
  align-items: center;
  font-weight: 400;
  color: #343a40;
  margin-bottom: 0 !important;
}

.calender-image{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.button-la {
  display: flex;
  margin-top: 57px;
  justify-content: center;
}

.latest-articles__more-button {
  background-color: #ffffff;
  color: rgba(89, 40, 209, 1);
  border: none;
  font-weight: 600;
  width: fit-content;
  border-radius: 5px;
  font-size: 24px;
  cursor: pointer;
}

.latest-articles__more-button:hover{
  color:  rgba(59, 10, 179, 1);
}

@media (max-width: 1750px) {
  .article-card__image {
    height: 275px;
  }
}

@media (max-width: 1600px) {
  .article-card__image {
    height: 250px;
  }
}

@media (max-width: 1350px) {
  .article-card__image {
    height: 325px;
  }
}

@media (max-width: 900px) {
  .article-card__image {
    height: 275px;
  }
}

@media (max-width: 767px) {
  .article-card__image {
    height: 300px;
  }
}

@media (max-width: 1350px) and (min-width: 768px) {
  .latest-articles__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
  }
  .article-card {
    width: 100%;
    overflow: visible;
  }
}

@media (max-width: 1024px) {
  .latest-articles__container {
    margin: 0 65px;
  }
}

@media (max-width: 767px) {
  .latest-articles__container {
    margin: 0 40px;
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  .article-card {
    width: 100%;
  }
  .button-la {
    margin-top: 48px;
  }
  
}

@media (max-width: 480px) {
  .latest-articles__container {
    margin: 0 30px;
    gap: 20px;
  }
  .article-card {
    display: flex;
    flex-direction: row;
    padding: 14px;
    justify-content: space-between;
    border-radius: 8px;
    gap: 16px;
  }
  .article-card__image {
    height: 100px !important;
    width: 35.9%;
    border-radius: 5px;
  }
  .article-card__content{
    width: 62.66%;
  }
  .article-card__category {
    font-size: 10px !important;
    line-height: 14px;
    margin-top: 0;
    margin-bottom: 6px !important;
  }
  .article-card__title {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 6px !important;
  }
  .article-card__author{
    font-size: 12px !important;
  }
  .article-card__date{
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }
  .latest-articles__more-button {
    font-size: 16px !important;
  }
  .calender-image{
    width: 18px !important;
    height: 18px !important;
  }
  .button-la {
    margin-top: 24px;
  }
}



