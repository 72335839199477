.slider-container{
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 6%;
  justify-content: center;
  gap: 2.5rem;
  width:100%;
  height: 20rem;
}

.slider{
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  perspective: 500px;
}

.slide {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: transform, filter;
}

@media ( min-width: 2050px){
  .dots-navigation {
   margin-top: 180px !important;
 }
}

@media ( min-width: 1441px) and (max-width: 1700px) {
   .dots-navigation {
    margin-top: 130px !important;
  }
}

@media ( min-width: 1251px) and (max-width: 1440px) {
   .dots-navigation {
    margin-top: 110px !important;
  }
}

@media ( min-width: 1101px) and (max-width: 1250px) {
   .dots-navigation {
    margin-top: 90px !important;
  }
}

@media ( min-width: 701px) and (max-width: 1100px) {
   .dots-navigation {
    margin-top: 70px !important;
  }
}

@media ( min-width: 501px) and (max-width: 700px) {
   .dots-navigation {
    margin-top: 0px !important;
  }
}

@media ( min-width: 426px) and (max-width: 500px) {
   .dots-navigation {
    margin-top: 0px !important;
  }
}

@media ( min-width: 376px) and (max-width: 425px) {
   .dots-navigation {
    margin-top: 0px !important;
  }
}

@media  (max-width: 375px) {
   .dots-navigation {
    margin-top: 0px !important;
  }
}

.slide-info {
  margin: 0px 105px;
  
}

@media ( min-width: 1879px){
  .slide-info {
   height:65px;
  }
}

@media ( min-width: 1441px) and (max-width: 1878px) {
  .slide-info {
    height:93px;
   }
}

@media ( min-width: 1356px) and (max-width: 1440px) {
  .slide-info {
    height:85px;
   }
}

@media ( min-width: 1101px) and (max-width: 1355px) {
  .slide-info {
    height:115px;
   }
}

@media ( min-width: 1072px) and (max-width: 1100px) {
  .slide-info {
    height:100px ;
   }
}

@media ( min-width: 1025px) and (max-width: 1071px) {
  .slide-info {
    height:125px ;
   }
}

@media ( min-width: 962px) and (max-width: 1024px) {
  .slide-info {
    height:105px ;
   }
}

@media ( min-width: 806px) and (max-width: 961px) {
  .slide-info {
    height:135px ;
   }
}

@media ( min-width: 768px) and (max-width: 805px) {
  .slide-info {
    height:165px ;
   }
}

@media ( min-width: 756px) and (max-width: 767px) {
  .slide-info {
    height:150px ;
   }
}

@media ( min-width: 701px) and (max-width: 755px) {
  .slide-info {
    height:170px ;
   }
}

@media ( min-width: 650px) and (max-width: 700px) {
  .slide-info {
    height:160px ;
   }
}

@media ( min-width: 573px) and (max-width: 649px) {
  .slide-info {
    height:185px ;
   }
}

@media ( min-width: 506px) and (max-width: 572px) {
  .slide-info {
    height:215px ;
   }
}

@media ( min-width: 481px) and (max-width: 505px) {
  .slide-info {
    height:245px ;
   }
}

@media ( min-width: 442px) and (max-width: 480px) {
  .slide-info {
    height:190px ;
   }
}

@media ( min-width: 426px) and (max-width: 441px) {
  .slide-info {
    height:215px ;
   }
}

@media ( min-width: 408px) and (max-width: 425px) {
  .slide-info {
    height:200px ;
   }
}

@media ( min-width: 391px) and (max-width: 407px) {
  .slide-info {
    height:225px ;
   }
}

@media ( min-width: 376px) and (max-width: 390px) {
  .slide-info {
    height:240px ;
   }
}

@media ( min-width: 354px) and (max-width: 375px) {
  .slide-info {
    height:235px ;
   }
}

@media ( min-width: 322px) and (max-width: 353px) {
  .slide-info {
    height:255px ;
   }
}

@media (max-width: 321px) {
  .slide-info {
    height:280px ;
   }
}

@media (max-width: 1000px) {
  .slider-container .slide {
    width: 63%; 
  }
  .slide {
    border-radius: 16px !important;
  }
  .slide-img{
    border-radius: 16px !important;
  }
}

@media (max-width: 767px) {
  .slider-container .slide {
    width: 67%; 
  }
}

@media (max-width: 500px) {
  .slider-container{
    margin-top: 0% !important;
  }
  
}

.slide-content{
  width: 100%;
  object-fit: fill;
}

.slide-desc{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #343a40;
  margin-bottom: 0px !important;
}

.highlight{
  font-size: 18px;
  color: #5928d1;
  font-weight: 600;
}

.slide-img{
  width: 100%;
  border-radius: 40px;
}

.dots-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 2px 3px;
  background-color: #e0d4ff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #5928d1;
}

@media (max-width: 1024px){
  .slide-info {
    margin: 0 50px;
  }
  .slide-desc {
    font-size: 18px;
  }
}

@media (max-width: 767px){
  .slide-info {
    margin: 0 25px;
  }
  .slide-desc{
    font-size: 18px;
  }
}

@media (max-width: 480px){
  .slide-info {
    margin: 0 15px;
  }
  .slide-desc {
    font-size: 16px;
    line-height: 24px;
  }
  .highlight{
    font-size: 16px;
  }
}



 

