.products-container {
  /* max-width: 1716px; */
  width: 100%;
  
}
/* #products {
  border-bottom: 2px solid transparent; 
 border-image: linear-gradient(to right, rgba(251, 250, 255, 0)0%, rgba(53, 96, 243, 1)44%, rgba(113, 4, 188, 1)57%,rgba(251, 250, 255, 0)98%) 1;
} */
.products-grid {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 0px 120px;
  justify-content: center;
}
.product-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.product-content{
  flex: 1;
}
.product-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.product-title{
  color: #5928d1;
}

.product-text h2 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
  color: #5928d1;
  width: fit-content;
}
.product-text h5 {
  font-size: 20px;
  line-height: 23.5px;
  font-weight: 600;
  color: #484848;
}
.product-desc p {
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 28px;
  color: #343a40;
  margin-bottom: 8px !important;
}
.productBtn {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 42px !important;
  font-size: 16px !important;
  color: #ffffff;
  background: #5928d1;
}

.productBtn:hover {
  color: #ffffff;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
}
.product-image-wrapper{
  flex: 1;
  max-height: 430px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 4rem 0;
}
.product-image{
  background-color: white;
  height: 330px;
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
@media (max-width: 1440px) {
  .products-grid {
    margin: 0px 120px;
  }
}
/* @media (max-width: 1280px) {
  .products-container {
    max-width: 1040px;
  }
  .product-image-wrapper{
    max-height: 460px;
  }
} */
@media (max-width: 1024px) {
  .product-text {
    max-width: 100%;
  }
  .product-item {
    gap: 20px;
    /* margin: 0 65px; */
  }
  .products-grid {
    margin: 0px 65px;
  }
}

@media (max-width: 767px) {
  .products-grid {
    margin: 0px 40px !important;
  }
  .product-item {
    flex-direction: column !important;
  }
  .product-image-wrapper{
    width: 100%;
    padding: 4rem;
    background: linear-gradient(90deg, #f4efff 0%, #f8f8f8 100%) !important;
  }
  .product-content{
    width: 100%;
  }
  .product-image{
    height: 280px;
    max-width: 100%;
  }
  .product-text {
    margin-bottom: 2.8rem;
    text-align: center;
    align-items: center;
  }

  .product-text h2 {
    font-size: 2rem;
  }
}

  
@media (max-width: 480px) {
  .products-grid {
    margin: 0px 30px !important;
  }
}

@media screen and (max-width: 576px) {
  .product-text h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  products-grid {
    margin: 0px 30px;
  }
}
@media (max-width: 425px) {
  .product-image-wrapper{
    width: 100%;
    padding: 2rem;
  }
  .product-content{
    width: 100%;
  }
  .product-image{
    height: 215px;
    max-width: 100%;
  }
  .productBtn {
    width: 50%;
    line-height: 10px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .productBtn {
    font-size: 18px !important;
    width: 40%;
  }
}

.modal-title{
  margin-bottom: 24px !important;
}
.modal-pr-desc {
  max-width: 525px;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
}

.product-form .form-group input {
  border-radius: 8px;
  padding: 23px 14px;
  font-size: 16px;
  font-weight: 400;
  background-color: #FAFAFA;
  color: #898989;
}
.custom-modal .modal-content {
  border-radius: 10px;
}
.prod-modal-btn {
  margin-top: 1rem;
}

