.techstack-container {
  max-width: 100%;
  margin: 0 105px;
}

.slide-container-ts {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 35px;
}



.image-row-ts {
  display: flex;
  gap: 2%;
  justify-content: flex-start;
  align-items: center;
}

.techstackImageContainer {
  position: relative;
  width: 10.75%;
  height: 100px;
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

#techstack .title {
  margin-bottom: 0.5px !important;
}



.techstackImageContainer:hover {
  transform: translateY(-5px);
  transition: 0.5s;
}

.techstack-progress-bar {
  position: relative;
  width: 100%;
  max-width: 160px;
  height: 8px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 8px;
}

.techstack-progress-bar .techstack-progress-segment {
  height: 100%;
  width: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
}

.techstack-progress-bar .techstack-progress-segment.active {
  background-color: #5928d1;
  width: 80px;
  cursor: default;
}

.techstack-desc {
  margin-top: 24px !important;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .slide-container-ts {
    margin-bottom: 40px !important;
  }

  .techstack-desc {
    font-size: 18px;
    margin-top: 16px !important;
  }
}

@media (max-width: 425px) {
  .techstack-desc {
    font-size: 16px;
    margin-top: 8px !important;
  }
}

.techstackImageContainer img{
  position: absolute;
  transform: scale(0.9);
}


.category-tabs {
  margin-bottom: 60px !important;
  width: 798px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 5px 22px 0px #0000001a;
  padding: 16px;
  border-radius: 16px;
}

.category-tabs .techstack-tab {
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  background-color: #eae2ff;
  color: #5928d1;
  width: 242px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.category-tabs .techstack-tab.active {
  background-color: #5928d1;
  color: white;
}

.hide-on-desktop {
  @media (min-width: 1281px) {
    display: none;
  }
}

.techstackImageContainer .bg-16 {
  width: 151px;
  height: 42px;
  object-fit: none;
  object-position:  -10px -688px;
}

.techstackImageContainer .bg-1 {
  width: 119px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -608px;
}

.techstackImageContainer .bg-2 {
  width: 137px;
  height: 60px;
  object-fit: none;
  object-position:  -601px -170px;
}

.techstackImageContainer .bg-3 {
  width: 45px;
  height: 60px;
  object-fit: none;
  object-position:  -217px -1054px;
}

.techstackImageContainer .bg-4 {
  width: 149px;
  height: 60px;
  object-fit: none;
  object-position:  -424px -250px;
}

.techstackImageContainer .bg-5 {
  width: 119px;
  height: 60px;
  object-fit: none;
  object-position:  -149px -608px;
}

.techstackImageContainer .bg-6 {
  width: 39px;
  height: 60px;
  object-fit: none;
  object-position:  -406px -1054px;
}

.techstackImageContainer .bg-7 {
  width: 157px;
  height: 60px;
  object-fit: none;
  object-position:  -424px -10px;
}

.techstackImageContainer .bg-8 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -10px;
}

.techstackImageContainer .bg-9 {
  width: 169px;
  height: 50px;
  object-fit: none;
  object-position:  -10px -470px;
}

.techstackImageContainer .bg-10 {
  width: 147px;
  height: 50px;
  object-fit: none;
  object-position:  -199px -470px;
}

.techstackImageContainer .bg-11 {
  width: 89px;
  height: 60px;
  object-fit: none;
  object-position:  -234px -814px;
}

.techstackImageContainer .bg-12 {
  width: 151px;
  height: 48px;
  object-fit: none;
  object-position:  -10px -540px;
}

.techstackImageContainer .bg-13 {
  width: 113px;
  height: 60px;
  object-fit: none;
  object-position:  -560px -608px;
}

.techstackImageContainer .bg-14 {
  width: 177px;
  height: 36px;
  object-fit: none;
  object-position:  -181px -540px;
}

.techstackImageContainer .bg-15 {
  width: 71px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -730px;
}

.techstackImageContainer .bg-17 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -90px;
}

.techstackImageContainer .bg-18 {
  width: 89px;
  height: 60px;
  object-fit: none;
  object-position:  -343px -814px;
}

.techstackImageContainer .bg-19 {
  width: 93px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -570px;
}

.techstackImageContainer .bg-20 {
  width: 135px;
  height: 50px;
  object-fit: none;
  object-position:  -601px -470px;
}

.techstackImageContainer .bg-21 {
  width: 151px;
  height: 32px;
  object-fit: none;
  object-position:  -575px -540px;
}

.techstackImageContainer .bg-22 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -170px;
}

.techstackImageContainer .bg-23 {
  width: 63px;
  height: 60px;
  object-fit: none;
  object-position:  -623px -894px;
}

.techstackImageContainer .bg-24 {
  width: 177px;
  height: 34px;
  object-fit: none;
  object-position:  -378px -540px;
}

.techstackImageContainer .bg-25 {
  width: 181px;
  height: 60px;
  object-fit: none;
  object-position:  -223px -10px;
}

.techstackImageContainer .bg-26 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -250px;
}

.techstackImageContainer .bg-27 {
  width: 83px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -10px;
}

.techstackImageContainer .bg-28 {
  width: 67px;
  height: 60px;
  object-fit: none;
  object-position:  -190px -894px;
}

.techstackImageContainer .bg-29 {
  width: 147px;
  height: 50px;
  object-fit: none;
  object-position:  -366px -470px;
}

.techstackImageContainer .bg-30 {
  width: 91px;
  height: 60px;
  object-fit: none;
  object-position:  -123px -814px;
}

.techstackImageContainer .bg-31 {
  width: 71px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -810px;
}

.techstackImageContainer .bg-32 {
  width: 73px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -570px;
}

.techstackImageContainer .bg-33 {
  width: 183px;
  height: 50px;
  object-fit: none;
  object-position:  -219px -250px;
}

.techstackImageContainer .bg-34 {
  width: 89px;
  height: 60px;
  object-fit: none;
  object-position:  -452px -814px;
}

.techstackImageContainer .bg-35 {
  width: 57px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -170px;
}

.techstackImageContainer .bg-36 {
  width: 115px;
  height: 36px;
  object-fit: none;
  object-position:  -292px -750px;
}

.techstackImageContainer .bg-37 {
  width: 117px;
  height: 36px;
  object-fit: none;
  object-position:  -155px -750px;
}

.techstackImageContainer .bg-38 {
  width: 181px;
  height: 32px;
  object-fit: none;
  object-position:  -181px -688px;
}

.techstackImageContainer .bg-39 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -330px;
}

.techstackImageContainer .bg-40 {
  width: 65px;
  height: 60px;
  object-fit: none;
  object-position:  -538px -894px;
}

.techstackImageContainer .bg-41 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -410px;
}

.techstackImageContainer .bg-42 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -410px;
}

.techstackImageContainer .bg-43 {
  width: 85px;
  height: 60px;
  object-fit: none;
  object-position:  -775px -814px;
}

.techstackImageContainer .bg-44 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -490px;
}

.techstackImageContainer .bg-45 {
  width: 73px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -650px;
}

.techstackImageContainer .bg-46 {
  width: 151px;
  height: 60px;
  object-fit: none;
  object-position:  -424px -170px;
}

.techstackImageContainer .bg-47 {
  width: 99px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -490px;
}

.techstackImageContainer .bg-48 {
  width: 159px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -320px;
}

.techstackImageContainer .bg-49 {
  width: 189px;
  height: 50px;
  object-fit: none;
  object-position:  -209px -170px;
}

.techstackImageContainer .bg-50 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -570px;
}

.techstackImageContainer .bg-51 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -650px;
}

.techstackImageContainer .bg-52 {
  width: 193px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -10px;
}

.techstackImageContainer .bg-53 {
  width: 117px;
  height: 60px;
  object-fit: none;
  object-position:  -288px -608px;
}

.techstackImageContainer .bg-54 {
  width: 109px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -90px;
}

.techstackImageContainer .bg-55 {
  width: 191px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -90px;
}

.techstackImageContainer .bg-56 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -730px;
}

.techstackImageContainer .bg-57 {
  width: 79px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -170px;
}

.techstackImageContainer .bg-58 {
  width: 77px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -250px;
}

.techstackImageContainer .bg-59 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -810px;
}

.techstackImageContainer .bg-60 {
  width: 59px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -10px;
}

.techstackImageContainer .bg-61 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -1002px -890px;
}

.techstackImageContainer .bg-62 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -490px;
}

.techstackImageContainer .bg-63 {
  width: 101px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -250px;
}

.techstackImageContainer .bg-64 {
  width: 189px;
  height: 50px;
  object-fit: none;
  object-position:  -10px -250px;
}

.techstackImageContainer .bg-65 {
  width: 93px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -814px;
}

.techstackImageContainer .bg-66 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -974px;
}

.techstackImageContainer .bg-67 {
  width: 111px;
  height: 36px;
  object-fit: none;
  object-position:  -427px -750px;
}

.techstackImageContainer .bg-68 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -91px -974px;
}

.techstackImageContainer .bg-69 {
  width: 57px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -250px;
}

.techstackImageContainer .bg-70 {
  width: 145px;
  height: 50px;
  object-fit: none;
  object-position:  -601px -250px;
}

.techstackImageContainer .bg-71 {
  width: 101px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -330px;
}

.techstackImageContainer .bg-72 {
  width: 47px;
  height: 60px;
  object-fit: none;
  object-position:  -150px -1054px;
}

.techstackImageContainer .bg-73 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -172px -974px;
}

.techstackImageContainer .bg-74 {
  width: 87px;
  height: 60px;
  object-fit: none;
  object-position:  -561px -814px;
}

.techstackImageContainer .bg-75 {
  width: 157px;
  height: 50px;
  object-fit: none;
  object-position:  -424px -330px;
}

.techstackImageContainer .bg-76 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -253px -974px;
}

.techstackImageContainer .bg-77 {
  width: 83px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -90px;
}

.techstackImageContainer .bg-78 {
  width: 43px;
  height: 60px;
  object-fit: none;
  object-position:  -282px -1054px;
}

.techstackImageContainer .bg-79 {
  width: 159px;
  height: 60px;
  object-fit: none;
  object-position:  -189px -320px;
}

.techstackImageContainer .bg-80 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -334px -974px;
}

.techstackImageContainer .bg-81 {
  width: 67px;
  height: 60px;
  object-fit: none;
  object-position:  -277px -894px;
}

.techstackImageContainer .bg-82 {
  width: 189px;
  height: 30px;
  object-fit: none;
  object-position:  -382px -688px;
}

.techstackImageContainer .bg-83 {
  width: 67px;
  height: 60px;
  object-fit: none;
  object-position:  -364px -894px;
}

.techstackImageContainer .bg-84 {
  width: 77px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -330px;
}

.techstackImageContainer .bg-85 {
  width: 175px;
  height: 50px;
  object-fit: none;
  object-position:  -10px -400px;
}

.techstackImageContainer .bg-86 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -415px -974px;
}

.techstackImageContainer .bg-87 {
  width: 35px;
  height: 60px;
  object-fit: none;
  object-position:  -368px -320px;
}

.techstackImageContainer .bg-88 {
  width: 189px;
  height: 44px;
  object-fit: none;
  object-position:  -205px -400px;
}

.techstackImageContainer .bg-89 {
  width: 57px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -330px;
}

.techstackImageContainer .bg-90 {
  width: 41px;
  height: 60px;
  object-fit: none;
  object-position:  -345px -1054px;
}

.techstackImageContainer .bg-91 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -570px;
}

.techstackImageContainer .bg-92 {
  width: 113px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -10px;
}

.techstackImageContainer .bg-93 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -650px;
}

.techstackImageContainer .bg-94 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -730px;
}

.techstackImageContainer .bg-95 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -810px;
}

.techstackImageContainer .bg-96 {
  width: 55px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -890px;
}

.techstackImageContainer .bg-97 {
  width: 157px;
  height: 60px;
  object-fit: none;
  object-position:  -424px -90px;
}

.techstackImageContainer .bg-98 {
  width: 49px;
  height: 60px;
  object-fit: none;
  object-position:  -81px -1054px;
}

.techstackImageContainer .bg-99 {
  width: 35px;
  height: 60px;
  object-fit: none;
  object-position:  -524px -1054px;
}

.techstackImageContainer .bg-100 {
  width: 145px;
  height: 60px;
  object-fit: none;
  object-position:  -601px -10px;
}

.techstackImageContainer .bg-101 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -496px -974px;
}

.techstackImageContainer .bg-102 {
  width: 115px;
  height: 60px;
  object-fit: none;
  object-position:  -425px -608px;
}

.techstackImageContainer .bg-103 {
  width: 75px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -490px;
}

.techstackImageContainer .bg-104 {
  width: 139px;
  height: 60px;
  object-fit: none;
  object-position:  -601px -90px;
}

.techstackImageContainer .bg-105 {
  width: 121px;
  height: 60px;
  object-fit: none;
  object-position:  -601px -390px;
}

.techstackImageContainer .bg-106 {
  width: 71px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -894px;
}

.techstackImageContainer .bg-107 {
  width: 181px;
  height: 60px;
  object-fit: none;
  object-position:  -223px -90px;
}

.techstackImageContainer .bg-108 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -577px -974px;
}

.techstackImageContainer .bg-109 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -658px -974px;
}

.techstackImageContainer .bg-110 {
  width: 51px;
  height: 60px;
  object-fit: none;
  object-position:  -693px -608px;
}

.techstackImageContainer .bg-111 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -739px -974px;
}

.techstackImageContainer .bg-112 {
  width: 63px;
  height: 60px;
  object-fit: none;
  object-position:  -706px -894px;
}

.techstackImageContainer .bg-113 {
  width: 51px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -1054px;
}

.techstackImageContainer .bg-114 {
  width: 125px;
  height: 44px;
  object-fit: none;
  object-position:  -10px -750px;
}

.techstackImageContainer .bg-115 {
  width: 157px;
  height: 40px;
  object-fit: none;
  object-position:  -414px -400px;
}

.techstackImageContainer .bg-116 {
  width: 53px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -970px;
}

.techstackImageContainer .bg-117 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -820px -974px;
}

.techstackImageContainer .bg-118 {
  width: 69px;
  height: 60px;
  object-fit: none;
  object-position:  -101px -894px;
}

.techstackImageContainer .bg-119 {
  width: 63px;
  height: 60px;
  object-fit: none;
  object-position:  -789px -894px;
}

.techstackImageContainer .bg-120 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -901px -974px;
}

.techstackImageContainer .bg-121 {
  width: 101px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -410px;
}

.techstackImageContainer .bg-122 {
  width: 63px;
  height: 60px;
  object-fit: none;
  object-position:  -872px -894px;
}

.techstackImageContainer .bg-123 {
  width: 77px;
  height: 60px;
  object-fit: none;
  object-position:  -899px -410px;
}

.techstackImageContainer .bg-124 {
  width: 67px;
  height: 60px;
  object-fit: none;
  object-position:  -451px -894px;
}

.techstackImageContainer .bg-125 {
  width: 179px;
  height: 60px;
  object-fit: none;
  object-position:  -10px -170px;
}

.techstackImageContainer .bg-126 {
  width: 59px;
  height: 60px;
  object-fit: none;
  object-position:  -1083px -90px;
}

.techstackImageContainer .bg-127 {
  width: 103px;
  height: 60px;
  object-fit: none;
  object-position:  -766px -170px;
}

.techstackImageContainer .bg-128 {
  width: 143px;
  height: 50px;
  object-fit: none;
  object-position:  -601px -320px;
}

.techstackImageContainer .bg-129 {
  width: 87px;
  height: 60px;
  object-fit: none;
  object-position:  -668px -814px;
}

.techstackImageContainer .bg-130 {
  width: 39px;
  height: 60px;
  object-fit: none;
  object-position:  -465px -1054px;
}

.techstackImageContainer .bg-131 {
  width: 61px;
  height: 60px;
  object-fit: none;
  object-position:  -982px -974px;
}

@media (min-width: 2300px) {
  .techstackImageContainer img{
    transform: scale(1.2);
  }
}
@media (max-width: 1800px) {
  .techstackImageContainer img{
    transform: scale(0.7);
  }
}
@media (min-width: 1401px) and (max-width: 1440px) {
  .techstack-container {
    margin: 0 105px;
  }
  
  .image-row-ts {
    gap: 2.3%;
  }
  
  .techstackImageContainer img{
    transform: scale(0.7);
  }
  .techstackImageContainer .bg-82 , .techstackImageContainer .bg-88 , .techstackImageContainer .bg-49{
    transform: scale(0.6);
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .techstackImageContainer img{
    transform: scale(0.6);
  }
}

@media (min-width: 1100px) and(max-width: 1299px) {
  .techstackImageContainer img, .techstackImageContainer .bg-82 , .techstackImageContainer .bg-88{
    transform: scale(0.4);
  }
  .techstackImageContainer .bg-129 {
    transform: scale(0.6);
  }
}
@media (max-width: 1099px) {
  .techstack-container {
    margin: 0 105px;
  }

  .slide-container-ts {
    margin-bottom: 60px;
    gap: 40px;
  }

  .image-row-ts {
    gap: 2%;
  }

  .techstackImageContainer {
    width: 23.5%;
  }
  .techstackImageContainer img{
    transform: scale(0.8);
  }
}

@media (max-width: 1024px) {
  .techstack-container {
    margin: 0 50px;
  }



  .category-tabs {
    width: 100%;
    margin: 0 50px;
  }

  .image-row-ts .techstackImage {
    width: 24%;
    height: 50px;
  }

  .category-tabs .techstack-tab {
    width: 33.33%;
    height: 68px;
  }
}

@media ((min-width: 481px) and (max-width: 700px)) {
  .image-row-ts {
    gap: 2% !important;
  }
}

@media (max-width: 768px) {
  .techstack-container {
    margin: 0 50px;
  }

  .image-row-ts {
    gap: 2%;
  }

  .category-tabs {
    width: 100%;
    padding: 20px;
    margin: 0 50px;
  }

  .category-tabs .techstack-tab {
    font-size: 18px;
    width: 33.33%;
    height: 56px;
  }

  .techstack-desc {
    font-size: 18px;
    margin-top: 16px !important;
  }
  .techstackImageContainer .bg-82 {
    transform: scale(0.7);
  }
}

@media (max-width: 767px) {
  .image-row-ts {
    gap: 5%;
  }

  .techstackImageContainer img{
    transform: scale(0.6);
  }
}
@media (max-width: 600px) {
  .techstackImageContainer img{
    transform: scale(0.5);
  }
}
@media (min-width: 375px) and (max-width: 480px) {
  .techstackImageContainer .bg-125 , .techstackImageContainer .bg-82 , .techstackImageContainer .bg-88 , .techstackImageContainer .bg-85 ,  .techstackImageContainer .bg-38 , .techstackImageContainer .bg-49 , .techstackImageContainer .bg-24 , .techstackImageContainer .bg-14 ,  .techstackImageContainer .bg-9 ,  .techstackImageContainer .bg-33, .techstackImageContainer .bg-25 ,  .techstackImageContainer .bg-64,  .techstackImageContainer .bg-107{
    transform: scale(0.5);
  }
  .techstackImageContainer img{
    transform: scale(0.6);
  }
}
@media (max-width: 480px) {
  .techstack-container {
    margin: 0 16px;
  }

  .image-row-ts {
    gap: 5%;
  }

  .techstackImageContainer {
    width: 30%;
  }

  .category-tabs {
    width: 100%;
    height: 140px;
    gap: 10px;
    padding: 12px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
  }

  .category-tabs .techstack-tab {
    font-size: 14px !important;
    width: 100%;
  }

  .techstack-tab-row1 {
    display: flex;
    gap: 10px;
    width: 100%;
    padding-top: 5px;
  }

  .techstack-tab-row2 {
    width: 100%;
    padding-bottom: 5px;
  }
  .slide-container-ts {
    gap: 0;
  }
  
}

@media (max-width: 425px) {
  .techstack-desc {
    font-size: 16px;
    margin-top: 8px !important;
  }
}

@media (max-width: 375px) {
  .techstack-container {
    margin: 0 16px;
  }

  .image-row-ts {
    gap: 5%;
  }

  

  .category-tabs {
    height: 140px;
    gap: 10px;
    padding: 12px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .category-tabs .techstack-tab {
    font-size: 14px !important;
    width: 100%;
  }

  .techstack-tab-row1 {
    display: flex;
    gap: 10px;
    width: 100%;
    padding-top: 5px;
  }

  .techstack-tab-row2 {
    width: 100%;
    padding-bottom: 5px;
  }
  
}

@media (max-width: 320px) {
  .techstack-container {
    margin: 0 16px;
  }

  .image-row-ts {
    gap: 7%;
  }

  .techstackImage {
    width: 28%;
    height: 70px;
  }
  .techstackImageContainer img{
    transform: scale(0.5);
  }
  .techstackImageContainer .bg-7 ,  .techstackImageContainer .bg-125,.techstackImageContainer .bg-9 , .techstackImageContainer .bg-25 ,  .techstackImageContainer .bg-33, .techstackImageContainer .bg-38, .techstackImageContainer .bg-48, .techstackImageContainer .bg-49, .techstackImageContainer .bg-55,.techstackImageContainer .bg-82,.techstackImageContainer .bg-85,.techstackImageContainer .bg-88,.techstackImageContainer .bg-79,.techstackImageContainer .bg-24,.techstackImageContainer .bg-14{
    transform: scale(0.4);
  }
  .techstackImageContainer .bg-58, .techstackImageContainer .bg-60 {
    transform: scale(0.6);
  }
}