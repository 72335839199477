.modal-body .title {
  margin-bottom: 2rem;
}
.modal-body .title h2 {
  font-size: 32px;
  font-weight: 600;
}
.form-label{
  font-size: 16px;
  font-weight: 500;
}
.form-inputs {
  border: 1px solid #ced1da;
  color: #777777;
  background-color: #FAFAFA;
  font-weight: 400;
  border-radius: 8px;
  height: 48px;
  padding: 15px 14px;
}

textarea.form-control {
  resize: none;
}

.form-divider {
  margin: 1rem 0;
  text-align: center;
  border-bottom: 1px solid #ced1da;
  line-height: 0.1em;
}

.form-divider span {
  color: #575757;
  font-weight: 600;
  background: #fff;
  font-size: 16px;
  padding: 0 10px;
}
.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.or {
  color: #777777;
  font-weight: bold;
  position: absolute;
  top: 48%;
  left: 48%;
}
.form-details{
  display: flex;
  justify-content: center;
  gap: 16px;
}
.form-details .form-group{
  flex: 1;
}
.whatsapp_phonenumber_submit_btn:disabled{
  cursor: not-allowed;
}
.modalBtn{
  font-weight: 600;
}
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1024px) {
  .contact-container .react-international-phone-input-container .react-international-phone-country-selector-button {
    height: 32px !important;
  }
  .contact-container .react-international-phone-input-container .react-international-phone-input {
    height: 32px !important;
  }
  .modal-header {
    padding: 0.75rem 1rem 0 0;
  }
  .modal-body .title h2 {
    font-size: 28px;
  }
  label.form-label {
    margin-bottom: 4px;
  }
  .form-divider {
    margin: 0.5rem 0;
  }
  .form-group{
    margin-bottom: 0.75rem;
  }
  .contact-container {
    margin-bottom: 8px;
  }
  .form-inputs {  
    height: 32px;
  }
  .whatsapp_phonenumber_submit_btn {
    padding: 0.5rem;
  }
}

@media (max-width: 767px) {
  .modal-body{
    text-align: left !important;
  }
}

@media (max-width: 480px) {
  .form-details{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .form-group {
    margin-bottom: 0.3rem;
  }
  .contact-container {
    margin-bottom: 4px;
  }
  .modal-body .title h2 {
    font-size: 24px;
  }

  .form-inputs {
    height: 30px;
  }
  .whatsapp_phonenumber_submit_btn {
    padding: 0.25rem;
    margin-top: 1rem;
  }
}
@media (max-width: 385px) {
  .modal-body .title h2 {
    font-size: 20px;
  }
}